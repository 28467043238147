import { Box, Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { H3 } from '@/components/Heading';
import ProductImage from '@/components/Image/ProductImage';
import ProductQuantityHandlerUnlogged from '@/components/ShoppingCart/ProductQuantityHandlerUnlogged';
import RemoveItemUnlogged from '@/components/ShoppingCart/RemoveItemUnlogged';
import NavLink from '@/components/UI/Links/NavLink';

import { Product } from '@/models/api/Product';
import { ItemsPropsUnlogged } from '@/models/props/ProductCardProps';
import { AppService } from '@/services';
import { ProductUtil } from '@/utils';

type ProductItemUnloggedProps = {
    product: Product;
    basket: ItemsPropsUnlogged[];
};

export function ProductItemUnlogged(props: ProductItemUnloggedProps) {
    const { product, basket } = props;
    const currency = AppService.getInstanceCurrency();
    const intl = useIntl();
    const { cartItemsUnlogged, fetchingData, editable } = useShoppingCart();
    const [basketItem, setBasketItem] = useState<ItemsPropsUnlogged>();
    const [currQuantity, setCurrQuantity] = useState<number>();
    const productUrl = ProductUtil.getProductUrl(product);
    const [price, setPrice] = useState<string>();
    const obj = useMemo(
        () => ({ product, intl, cartItemsUnlogged }),
        [product, intl, cartItemsUnlogged]
    );
    const funcPullQuantity = (q: number) => {
        setCurrQuantity(q);
    };

    useEffect(() => {
        if (basketItem) {
            setPrice((Math.round(basketItem?.grossPrice * 100) / 100).toFixed(2));
        }
    }, [basketItem]);

    useEffect(() => {
        const productItem = obj.cartItemsUnlogged?.find((i) => i.code === product.code);

        if (productItem) {
            setCurrQuantity(productItem.quantity);
            setBasketItem(productItem);
        }
    }, [basket, product, obj]);

    const addToCartDataLayer = useCallback(() => {
        const currency = AppService.getInstanceCurrency();
        const brand = intl.formatMessage({
            id: `data-layer.brand.${process.env.NEXT_PUBLIC_SELL_ZONE}`
        });
        const { categories, code, grossPrice, grossPriceBeforeDiscount, title } = product;
        const numberGrossPrice = Number(grossPrice?.replace(',', '.'));
        const numberGrossPriceBeforeDiscount = Number(grossPriceBeforeDiscount?.replace(',', '.'));
        const discount = Math.round(numberGrossPriceBeforeDiscount * 100 - numberGrossPrice * 100);
        const category = categories?.[0].subs?.[0].name || '';

        window.dataLayer.push({ ecommerce: 'null' });
        window.dataLayer.push({
            event: 'Koszyk',
            ecommerce: {
                currency: currency,
                value: numberGrossPrice,
                items: [
                    {
                        item_id: code,
                        item_name: title,
                        index: 1,
                        discount: discount / 100,
                        item_brand: brand,
                        item_category: category,
                        price: numberGrossPrice,
                        quantity: 1
                    }
                ]
            }
        });
    }, [product, intl]);

    return (
        <>
            {product && (
                <SimpleGrid columns={16} gap={4}>
                    <GridItem position="relative" colSpan={5}>
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            position="relative"
                            h="100%"
                            w="100%"
                        >
                            <ProductImage mainImage={product.mainImage} title={product.title} />
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={11}>
                        <Flex justifyContent="space-between" fontSize="sm" columnGap={4}>
                            <NavLink href={productUrl}>
                                <H3 size="h4" fontWeight="medium" mb={0}>
                                    {obj.product.title}
                                </H3>
                            </NavLink>
                            <Box ml="auto">
                                <RemoveItemUnlogged code={product.code} isDisabled={!editable} />
                            </Box>
                        </Flex>
                        <Text fontSize="small">
                            <FormattedMessage id="product-code" />: {product.code}
                        </Text>
                        <Box pt={3}>
                            {currQuantity !== undefined && (
                                <Flex
                                    direction="column"
                                    justifyContent="start"
                                    alignItems="start"
                                    gap={4}
                                    w="100%"
                                >
                                    <Flex alignItems="center">
                                        <Box
                                            width="min-content"
                                            minW="5rem"
                                            flexGrow={0}
                                            flexShrink={0}
                                        >
                                            <ProductQuantityHandlerUnlogged
                                                setQuantity={funcPullQuantity}
                                                code={product.code}
                                                grossPrice={product.grossPrice}
                                                quantity={currQuantity}
                                                maxOrder={30}
                                                size="small"
                                                fetchingData={fetchingData}
                                                incraseProductCountCallback={addToCartDataLayer}
                                            />
                                        </Box>
                                        {product.grossPrice && (
                                            <Text as="span" ml="2">
                                                &times;{' '}
                                                <FormattedPrice
                                                    value={product.grossPrice}
                                                    currency={currency}
                                                />
                                            </Text>
                                        )}
                                    </Flex>
                                    <Text
                                        fontSize="x-large"
                                        fontWeight="medium"
                                        whiteSpace="nowrap"
                                    >
                                        <FormattedPrice value={price} currency={currency} />
                                    </Text>
                                </Flex>
                            )}
                        </Box>
                    </GridItem>
                </SimpleGrid>
            )}
        </>
    );
}

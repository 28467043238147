import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { ProductUVPPrice } from '@/components/Product/ProductUVPPrice/ProductUVPPrice';
import PresentQuantityHandler from '@/components/ShoppingCart/PresentQuantityHandler';
import ProductQuantityHandler from '@/components/ShoppingCart/ProductQuantityHandler';

import { Product } from '@/models/api';
import { ErrorCode } from '@/models/api/Error';
import { DisabledReason } from '@/models/props/ProductCardProps';
import { AppService } from '@/services';

import { useShoppingCart } from '../../../context/shoppingCartContext';

export function ProductItemActions(
    props: Pick<
        Product,
        | 'code'
        | 'maximalProductCountInOrder'
        | 'priceGross'
        | 'priceGrossSingle'
        | 'netPrice'
        | 'currency'
        | 'priceGrossWas'
        | 'points'
        | 'grossPriceBeforeDiscount'
        | 'discount'
    > & {
        quantity: number;
        dir?: 'column' | 'row';
        justify?: 'space-between' | 'start';
        stock?: number;
        promotionCode?: string;
        showGrossPrice?: boolean;
        addToCartDataLayerCallback?: () => void;
    }
) {
    const {
        code,
        maximalProductCountInOrder,
        priceGross,
        priceGrossSingle,
        priceGrossWas,
        quantity,
        dir,
        justify,
        stock,
        promotionCode,
        points,
        grossPriceBeforeDiscount,
        discount,
        showGrossPrice = true,
        addToCartDataLayerCallback
    } = props;
    const currency = AppService.getInstanceCurrency();
    const { currentBasket, fetchingData } = useShoppingCart();
    const [currQuantity, setCurrQuantity] = useState<number>(quantity);
    const { controls } = currentBasket || {};
    const [priceGreossBeforeDiscount, setPriceGreossBeforeDiscount] = useState<string | undefined>(
        grossPriceBeforeDiscount
    );

    useEffect(() => {
        currentBasket?.products.forEach((item) => {
            if (item.code === code && item.quantity !== currQuantity) {
                setCurrQuantity(() => item.quantity);
            }
        });
    }, [code, currQuantity, currentBasket]);

    useEffect(() => {
        if (grossPriceBeforeDiscount) {
            const price = parseFloat(grossPriceBeforeDiscount.replace(',', '.')) * currQuantity;
            setPriceGreossBeforeDiscount(String(price));
        }
    }, [currQuantity, grossPriceBeforeDiscount]);

    return (
        <Flex
            direction={{ base: 'column', md: dir ?? 'column' }}
            justifyContent={justify ?? 'start'}
            gap={4}
            w="100%"
        >
            <Flex alignItems="center">
                {controls?.editable ? (
                    <>
                        <Box width="min-content" minW="5rem" flexGrow={0} flexShrink={0}>
                            {promotionCode ? (
                                <PresentQuantityHandler
                                    code={code}
                                    quantity={currQuantity}
                                    maxOrder={maximalProductCountInOrder}
                                    size="small"
                                    promotionCode={promotionCode}
                                    stock={stock}
                                    incrasePresentCountCallback={addToCartDataLayerCallback}
                                />
                            ) : (
                                <ProductQuantityHandler
                                    code={code}
                                    quantity={currQuantity}
                                    maxOrder={maximalProductCountInOrder}
                                    size="small"
                                    fetchingData={fetchingData}
                                    incraseProductCountCallback={addToCartDataLayerCallback}
                                />
                            )}
                        </Box>
                        {priceGrossSingle && showGrossPrice && (
                            <Text as="span" ml="2">
                                &times;{' '}
                                <FormattedPrice value={priceGrossSingle} currency={currency} />
                            </Text>
                        )}
                    </>
                ) : (
                    controls?.disabledReason === DisabledReason.PendingTransferPayment && (
                        <Text color="red">
                            <FormattedMessage id={ErrorCode.disabled} />
                        </Text>
                    )
                )}
            </Flex>
            {points > 0 && (
                <Text fontWeight="medium">
                    <FormattedMessage id="total-points" />: {points}
                </Text>
            )}
            <Flex alignItems="center" gap={2} lineHeight="none">
                {priceGrossWas && (
                    <Text
                        textDecoration="line-through"
                        pb="2px"
                        color="grey.600"
                        mr="10px"
                        ml={{ base: 'auto', md: 0 }}
                    >
                        <FormattedPrice value={priceGrossWas} currency={currency} />
                    </Text>
                )}
                <Text fontSize="x-large" fontWeight="medium" whiteSpace="nowrap">
                    <FormattedPrice value={priceGross} currency={currency} />
                </Text>
                {grossPriceBeforeDiscount && (
                    <ProductUVPPrice
                        grossPriceBeforeDiscount={priceGreossBeforeDiscount}
                        discount={discount}
                        currency={currency}
                        showCurrency={true}
                    />
                )}
                {discount !== undefined && discount < 0 && (
                    <>
                        <Text
                            color="green.500"
                            fontSize="12px"
                            fontWeight="medium"
                            bgColor="green.50"
                            paddingY="2px"
                            paddingX={1}
                            position="relative"
                        >
                            {discount.toString()}&#37;
                        </Text>
                    </>
                )}
            </Flex>
        </Flex>
    );
}
